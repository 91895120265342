import { FC } from 'react';
import { GenericProps } from '../../types/types';
import { CssBaseline, StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import { rootElement } from '../..';
import Poppins from '../../assets/fonts/Poppins/Poppins-Regular.ttf';

// boilerplate comopnent for custom MUI theme with Tailwind + CSSBaseline for style reset across browsers
const CustomMUITheme: FC<GenericProps> = ({ children }) => {

    // MUI setup with tailwind according to MUI docs here https://mui.com/material-ui/guides/interoperability/#tailwind-css
    // All `Portal`-related components need to have the the main app wrapper element as a container
    // so that the are in the subtree under the element used in the `important` option of the Tailwind's config.
    const theme = createTheme({
        palette: {
            primary: {
                main: '#FF4C73', // main yasu pink
            },
            mode: 'dark',
        },
        // set default font for entire MUI app
        typography: {
            fontFamily: 'Poppins, sans-serif',
        },
        components: {
            MuiCssBaseline: {
                // override default mui baseline font with custom font
                // redundant if also declaring this font face in global css file
                styleOverrides: {
                    '@font-face': {
                        fontFamily: 'Poppins',
                        src: `local('Poppins'), url(${Poppins}) format('truetype')`,
                    },
                }
            },
            MuiPopover: {
                defaultProps: {
                    container: rootElement,
                },
            },
            MuiPopper: {
                defaultProps: {
                    container: rootElement,
                },
            },
            MuiDialog: {
                defaultProps: {
                    container: rootElement,
                },
            },
            MuiModal: {
                defaultProps: {
                    container: rootElement,
                },
            },
        },
    });

    return (
        <>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {children}
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    );
}

export default CustomMUITheme;