import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import "./SupplementTable.scss";
import {
  Supplement,
  CategoryType,
  SupplementType,
  categoryOptionsArray,
  supplementsArray,
} from "./supplements.data.types";
import FullLegend from "./Legend/FullLegend/FullLegend";
import Disclaimer from "./Disclaimer/Disclaimer";
import body from "../../../../assets/images/BiologicalParts/Body.png";

const SupplementTable = () => {

  const [filter, setFilter] = useState<CategoryType | SupplementType | null>(
    "energized"
  );

  // dialog vars
  const [selectedSupplement, setSelectedSupplement] =
    useState<Supplement | null>(null);
  const [open, setOpen] = useState(false);

  // open dialog
  const openModal = (supplement: Supplement) => {
    setSelectedSupplement(supplement);
    setOpen(true);
  };

  // close dialog
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="SupplementTable">
      {/* category filters */}
      <div className="flex justify-center md:space-x-6 space-x-2 mb-6">
        {categoryOptionsArray.map((item, i) => (
          <Button
            variant="contained"
            key={i}
            onClick={() => setFilter(item.filter)}
            className={`w-full rounded-2xl p-2 text-xs sm:text-sm md:text-lg md:font-bold capitalize border-solid border-2 border-primary hover:bg-primary max-sm:h-8 grow ${
              filter === item.filter ? "bg-primary" : "bg-transparent"
            }`}
          >
            {item.text}
          </Button>
        ))}
      </div>

      {/* category filters description */}
      {categoryOptionsArray.find((item) => filter === item.filter) && (
        <div className="bg-primary p-4 rounded-2xl">
          <p className="font-quattro m-0 md:text-lg sm:text-sm text-xs">
            {
              categoryOptionsArray.find((item) => filter === item.filter)
                ?.description
            }
          </p>
        </div>
      )}

      {/* periodic table grid */}
      <Grid
        container
        spacing={{ xs: 2, sm: 3, lg: 3 }}
        columns={{ xs: 3, sm: 3, lg: 3 }}
        className="mt-1"
      >
        {supplementsArray.map((item, i) => (
          // each individual grid item
          <Grid item key={i} xs={1}>
            <Button
              onClick={() => openModal(item)}
              className={`h-full w-full text-white bg-${item.type} rounded-[20px] flex flex-col items-start p-2 md:p-5 md:h-[9rem] max-md:aspect-square overflow-hidden normal-case`}
            >
              <span className="text-xs sm:text-base text-left md:text-2xl font-bold leading-4 capitalize">
                {item.name}
              </span>
              {item.subtitle && (
                <span className="w-full text-left text-[0.625rem] sm:text-sm md:text-base leading-4 md:leading-6 font-normal text-ellipsis overflow-hidden">
                  {item.subtitle}
                </span>
              )}
            </Button>
          </Grid>
        ))}
      </Grid>

      <FullLegend className="hidden md:flex" />

      <Disclaimer />

      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="MuiDialog-Supplement"
      >
        <DialogTitle
          className="flex justify-between items-center"
          id="scroll-dialog-title"
        >
          <span className="md:text-4xl text-3xl font-bold">
            {selectedSupplement?.name}
          </span>
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            component="div"
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <h3 className="text-white">What is it?</h3>
            {selectedSupplement?.whatIsIt}

            <h3 className="text-white">What does it do?</h3>
            <div className="flex justify-center">
              <img src={body} alt="body" className="mb-4 sm:h-72 h-56" />
            </div>

            {selectedSupplement?.biologicalDetails}

            <h3 className="text-white mb-0">Science brought to you by:</h3>

            {selectedSupplement?.scienceLinks.map((scienceLink, i) => (
              <div className="font-quattro text-white my-1 text-sm" key={i}>
                {scienceLink.text}
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={scienceLink.link}
                  className="text-white decoration-white"
                >
                  {scienceLink.underlinedText}
                </Link>
              </div>
            ))}

            <p className="font-quattro text-white mt-5 text-sm">
              Disclaimer: All information is sourced from medical journals based
              on specific demographics and dosages, which may not apply
              universally. Please consult a healthcare professional before using
              supplements. This content is for educational purposes only.
            </p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SupplementTable;
