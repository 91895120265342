import { ReactNode } from "react";
import PopupBioTitle from "./PopupDetails/PopupBioTitle";
import PopupBioDesc from "./PopupDetails/PopupBioDesc";
import adrenalGlands from "../../../../assets/images/BiologicalParts/AdrenalGlands.png";
import blood from "../../../../assets/images/BiologicalParts/Blood.png";
import bones from "../../../../assets/images/BiologicalParts/Bones.png";
import brain from "../../../../assets/images/BiologicalParts/Brain.png";
import cells from "../../../../assets/images/BiologicalParts/Cells.png";
import eye from "../../../../assets/images/BiologicalParts/Eye.png";
import heart from "../../../../assets/images/BiologicalParts/Heart.png";
import immuneSystem from "../../../../assets/images/BiologicalParts/ImmuneSystem.png";
import kidneys from "../../../../assets/images/BiologicalParts/Kidneys.png";
import liver from "../../../../assets/images/BiologicalParts/Liver.png";
import muscles from "../../../../assets/images/BiologicalParts/Muscles.png";
import nerveCells from "../../../../assets/images/BiologicalParts/NerveCells.png";
import smallIntestines from "../../../../assets/images/BiologicalParts/SmallIntestines.png";
import skin from "../../../../assets/images/BiologicalParts/Skin.png";
import PopupHighlight from "./PopupDetails/PopupHighlight";

export type SupplementType =
  | "vitamin"
  | "mineral"
  | "aminoAcid"
  | "plantFamily";
export type CategoryType = "energized" | "calm" | "motivated" | "focused";

export type BiologicalPart =
  | "Brain"
  | "Eye"
  | "Immune System"
  | "Skin"
  | "Cells"
  | "Liver"
  | "Adrenal Glands"
  | "Small Intestines"
  | "Nerve Cells"
  | "Bones"
  | "Muscles"
  | "Heart"
  | "Kidneys"
  | "Blood"
  | "Brain, Immune System, & Heart";

export interface Supplement {
  name: string;
  subtitle?: string;
  type: SupplementType;
  whatIsIt: ReactNode;
  scienceLinks: {
    text: string;
    underlinedText: string;
    link: string;
  }[];
  biologicalDetails: ReactNode;
}

export const imageMap: { [key: string]: string } = {
  "Adrenal Glands": adrenalGlands,
  Blood: blood,
  Bones: bones,
  Brain: brain,
  Cells: cells,
  Eye: eye,
  Heart: heart,
  "Immune System": immuneSystem,
  Kidneys: kidneys,
  Liver: liver,
  Muscles: muscles,
  "Nerve Cells": nerveCells,
  "Small Intestines": smallIntestines,
  Skin: skin,
  Stomach: smallIntestines,
};

export const supplementOptionsArray: {
  text: string;
  filter: SupplementType;
}[] = [
  { text: "Vitamins", filter: "vitamin" },
  { text: "Minerals", filter: "mineral" },
  { text: "Acids", filter: "aminoAcid" },
  { text: "Herbs", filter: "plantFamily" },
];

export const categoryOptionsArray: {
  text: string;
  filter: CategoryType;
  description: string;
}[] = [
  {
    text: "Energy",
    filter: "energized",
    description:
      "Energy is the body's capacity to do work. It’s produced by breaking down nutrients such as carbohydrates, fats, and proteins in the digestive tract¹ ². Certain nutrients may support processes involved in energy metabolism, production, and absorption, helping tissues and cells convert fuel into usable energy.",
  },
  {
    text: "Calmness",
    filter: "calm",
    description:
      "Calm is the absence of anxiety, a future-oriented mood state that triggers an undue response to an event or situation that is mistakenly perceived as threatening.³ Certain nutrients may reduce anxiety and promote relaxation by contributing to the processes that regulate mood, stress reactions, and normal sleep.",
  },
  {
    text: "Motivation",
    filter: "motivated",
    description:
      "Motivation, the energizing of behavior in pursuit of a goal, is influenced by stress, health, and dopamine, a neurotransmitter associated with reward anticipation and goal-oriented behavior. Certain nutrients may promote motivation by helping regulate stress reactions, immune responses, and dopamine levels.⁵ ⁶",
  },
  {
    text: "Focus",
    filter: "focused",
    description:
      "Focus is the ability to pay attention. It involves the use of neurons (brain cells) to filter out irrelevant sensory information⁴. Certain nutrients may support neurons by aiding in their growth, survival, and function. This in turn, can help the brain process information more effectively and maintain focus.",
  },
];

export const supplementsArray: Supplement[] = [
  {
    name: "Vitamin B6",
    subtitle: "Pyridoxine",
    type: "vitamin",
    whatIsIt: (
      <>
        <PopupBioDesc>
          Vitamin B6, also known as pyridoxine, is an essential{" "}
          <PopupHighlight>water-soluble</PopupHighlight> vitamin that is
          widespread in foods, including meat, fish, nuts, beans, grains,
          fruits, and vegetables.
        </PopupBioDesc>
      </>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK554545",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK554545/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK470579",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK470579/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK557436",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK557436/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7019700",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC7019700/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC6472684",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC6472684/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK538510",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK538510/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK2263",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK2263/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK539702",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK539702/#:~:text=Introduction,carry%20out%20their%20essential%20functions.",
      },
      {
        text: "BMC: ",
        underlinedText: "Nutrition Journal",
        link: "https://nutritionj.biomedcentral.com/articles/10.1186/1475-2891-14-6",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Vitamin B6 is needed for the growth of major neurotransmitters like
          serotonin and dopamine, which are important in mood regulation.
        </PopupBioDesc>

        <PopupBioTitle>Immune System</PopupBioTitle>
        <PopupBioDesc>
          Vitamin B5 is necessary for the growth of{" "}
          <PopupHighlight>bile acids</PopupHighlight> in the liver. In the
          intestines, bile acids help to emulsify fats, aiding in their
          digestion and absorption.
        </PopupBioDesc>
        <PopupBioDesc>
          Vitamin B5 is also necessary for the growth of{" "}
          <PopupHighlight>fatty acids</PopupHighlight> in the liver. In cells,
          fatty acids function as structural “building blocks” of{" "}
          <PopupHighlight>cell membranes</PopupHighlight>, suppliers of energy,
          and <PopupHighlight>signaling molecules</PopupHighlight>.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Vitamin B5 plays a role in the{" "}
          <PopupHighlight>metabolism</PopupHighlight> of fats, energy
          production, cell growth, and the formation of{" "}
          <PopupHighlight>hormones</PopupHighlight>.
        </PopupBioDesc>
        <PopupBioDesc>
          Vitamin B5 also plays a role in{" "}
          <PopupHighlight>gene expression</PopupHighlight>, the process by which
          the information encoded in a <PopupHighlight>gene</PopupHighlight> is
          turned into a function.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Vitamin B12",
    subtitle: "Cobalamin",
    type: "vitamin",
    whatIsIt: (
      <>
        <PopupBioDesc>
          Vitamin B12, also known as cobalamin, is an essential{" "}
          <PopupHighlight>water-soluble</PopupHighlight> vitamin that is derived
          from animal products such as red meat, dairy, and eggs or sourced from
          fortified foods in synthetic form.
        </PopupBioDesc>
      </>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK554545",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK554545/",
      },
      {
        text: "NIH: ",
        underlinedText: "VitaminB12-HealthProfessional",
        link: "https://ods.od.nih.gov/factsheets/VitaminB12-HealthProfessional/",
      },
      {
        text: "NIH: ",
        underlinedText: "Nervous System",
        link: "https://www.nichd.nih.gov/health/topics/neuro/conditioninfo/parts",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC5017146",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC5017146/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK559132",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK559132/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC6222795",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC6222795/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK2263",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK2263/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK539702",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK539702/#:~:text=Introduction,carry%20out%20their%20essential%20functions.",
      },
      {
        text: "NIH: ",
        underlinedText: "Deoxyribonucleic-Acid-Fact-Sheet",
        link: "https://www.genome.gov/about-genomics/fact-sheets/Deoxyribonucleic-Acid-Fact-Sheet",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Required for the development and function of the{" "}
          <PopupHighlight>central nervous system</PopupHighlight> (CNS), which
          is made up of the brain and the spinal cord.
        </PopupBioDesc>
        <PopupBioDesc>
          Required for the growth of myelin in the{" "}
          <PopupHighlight>central nervous system</PopupHighlight> (CNS) and{" "}
          <PopupHighlight>peripheral nervous system</PopupHighlight> (PNS).{" "}
          <PopupHighlight>Myelin</PopupHighlight> is a multilayer wrapping of
          insulation formed by <PopupHighlight>glial cells</PopupHighlight>{" "}
          around the axons of neurons that is essential for rapid impulse
          transmission.
        </PopupBioDesc>

        <PopupBioTitle>Liver</PopupBioTitle>
        <PopupBioDesc>
          Required for the growth of fatty acids in the liver. In cells,{" "}
          <PopupHighlight>fatty acids</PopupHighlight> function as structural
          “building blocks” of <PopupHighlight>cell membranes</PopupHighlight>,
          suppliers of energy, and signaling molecules.
        </PopupBioDesc>

        <PopupBioTitle>Bones</PopupBioTitle>
        <PopupBioDesc>
          Required for the formation of healthy{" "}
          <PopupHighlight>red blood cells</PopupHighlight> in the bone marrow.{" "}
          <PopupHighlight>Red blood cells</PopupHighlight> are the functional
          component of the blood responsible for the transportation of gases and
          nutrients throughout the human body.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Required for the growth of <PopupHighlight>DNA</PopupHighlight> in
          cells. DNA is a molecule that contains the biological instructions
          that make each species unique.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Magnesium",
    subtitle: "Magnesium Glycinate",
    type: "mineral",
    whatIsIt: (
      <>
        <PopupBioDesc>
          Magnesium is an essential macro mineral found in foods such as fruits,
          vegetables, whole grains, legumes, nuts, dairy, meat, and fortified
          foods like cereal.
        </PopupBioDesc>
      </>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "NBK554545",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK554545/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7019700",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC7019700/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC5452159",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC5452159/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK538239",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK538239/",
      },
      {
        text: "NIH: ",
        underlinedText: "Magnesium-HealthProfessional",
        link: "https://ods.od.nih.gov/factsheets/Magnesium-HealthProfessional/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK553175",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK553175/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC3775240",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC3775240/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC5612831",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC5612831/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC4549665",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC4549665/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC4684116",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC4684116/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Needed for <PopupHighlight>neuromuscular</PopupHighlight> coordination
          and optimal <PopupHighlight>nerve transmission</PopupHighlight>.
          Protects against the overexcitation of nerves, which can lead to{" "}
          <PopupHighlight>oxidative stress</PopupHighlight> and neuronal cell
          death.
        </PopupBioDesc>

        <PopupBioTitle>Adrenal Glands</PopupBioTitle>
        <PopupBioDesc>
          Has been shown to reduce the production of cortisol, the body's stress
          hormone, by the adrenal glands. Magnesium may influence anxiety via
          the moderation of the stress response.
        </PopupBioDesc>

        <PopupBioTitle>Bones</PopupBioTitle>
        <PopupBioDesc>
          Contributes to the structural development of bone. Magnesium is
          essential to <PopupHighlight>osteoblasts</PopupHighlight>, cells
          responsible for aged bone resorption and{" "}
          <PopupHighlight>osteoclasts</PopupHighlight>, cells responsible for
          new bone formation.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Plays a predominant role in the production and utilization of{" "}
          <PopupHighlight>adenosine triphosphate</PopupHighlight> (ATP). ATP is
          commonly referred to as the “energy currency” of the cell, as it
          provides readily releasable energy for daily functioning.
        </PopupBioDesc>
        <PopupBioDesc>
          Plays a role in the <PopupHighlight>active transport</PopupHighlight>{" "}
          of <PopupHighlight>calcium</PopupHighlight> and{" "}
          <PopupHighlight>potassium ions</PopupHighlight> across cell{" "}
          <PopupHighlight>membranes</PopupHighlight>. The process is important
          to <PopupHighlight>nerve impulse</PopupHighlight> conduction, muscle
          contraction, and normal heart rhythm.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "L-Theanine",
    subtitle: "γ–Glutamylethylamide", 
    type: "aminoAcid",
    whatIsIt: (
      <PopupBioDesc>
        L-theanine is a unique <PopupHighlight>amino acid</PopupHighlight> and a
        natural component of tea. An amino acid is an organic compound that is
        required for the growth of body protein and other important{" "}
        <PopupHighlight>nitrogen</PopupHighlight>-containing compounds.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "18296328",
        link: "https://pubmed.ncbi.nlm.nih.gov/18296328/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK557845",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK557845/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK234922",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK234922/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC3507158",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC3507158/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC8080935",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC8080935/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Significantly increases the activity of{" "}
          <PopupHighlight>alpha waves</PopupHighlight>, which play a role in
          brain activity at different{" "}
          <PopupHighlight>frequencies</PopupHighlight>. An increase in alpha
          wave activity indicates that L-theanine relaxes the mind without
          inducing drowsiness.
        </PopupBioDesc>
        <PopupBioDesc>
          Has been shown to affect brain functions by relieving stress
          disorders, improving mood, and maintaining normal sleep.
        </PopupBioDesc>

        <PopupBioTitle>Nerve Cells</PopupBioTitle>
        <PopupBioDesc>
          Reported to have a neuroprotective effect. L-theanine can potentially
          reduce cognitive impairment because it promotes the growth of new
          neurons.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "L-Tyrosine",
    subtitle: "4-Hydroxyphenylalanine",
    type: "aminoAcid",
    whatIsIt: (
      <PopupBioDesc>
        L-tyrosine is a large non-essential{" "}
        <PopupHighlight>neutral amino acid</PopupHighlight> (LNAA) that is
        naturally present in food. An amino acid is an organic compound that is
        required for the growth of body protein and other important{" "}
        <PopupHighlight>nitrogen</PopupHighlight>-containing compounds.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "PMC6084775",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC6084775/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK557845",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK557845/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK234922",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK234922/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7019700",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC7019700/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK540977",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK540977/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC1863555",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC1863555/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          L-tyrosine is a precursor to{" "}
          <PopupHighlight>neurotransmitters</PopupHighlight> like{" "}
          <PopupHighlight>dopamine</PopupHighlight> and{" "}
          <PopupHighlight>noradrenaline</PopupHighlight>. Dopamine is important
          in mood regulation. Noradrenaline plays an essential role in the
          regulation of arousal, attention, cognitive function, and stress
          reactions.
        </PopupBioDesc>
        <PopupBioDesc>
          In young adults, L-tyrosine improved cognitive control functions such
          as <PopupHighlight>response inhibition</PopupHighlight>, task
          switching, and working memory, especially in demanding circumstances.
        </PopupBioDesc>
        <PopupBioDesc>
          Reported to prevent a decline in cognitive function in response to
          physical stress.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Sulforaphane",
    subtitle: "Broccoli Sprout Powder",
    type: "plantFamily",
    whatIsIt: (
      <PopupBioDesc>
        Sulforaphane (SFN) is an <PopupHighlight>isothiocyanate</PopupHighlight>{" "}
        (ITC), a biologically active small molecule found in some vegetables.
        Broccoli is a major source of SFN.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "PMC6611193",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC6611193/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7802872",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC7802872/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK26873",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK26873/",
      },
      {
        text: "Fronteirs: ",
        underlinedText: "10.3389",
        link: "https://www.frontiersin.org/journals/cell-and-developmental-biology/articles/10.3389/fcell.2021.645593/full",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Sulforaphane may have a promising role in{" "}
          <PopupHighlight>neuroprotection</PopupHighlight>. SFN exhibits strong{" "}
          <PopupHighlight>anti-inflammatory</PopupHighlight> and{" "}
          <PopupHighlight>antioxidant</PopupHighlight> properties which allow it
          to dramatically reduce <PopupHighlight>cytotoxicity</PopupHighlight>{" "}
          in the nervous system.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Sulforaphane plays a role in promoting and regulating cellular
          defenses to <PopupHighlight>oxidative stress</PopupHighlight> and
          alleviating the damage to cells.
        </PopupBioDesc>
        <PopupBioDesc>
          Sulforaphane also promotes{" "}
          <PopupHighlight>programmed cell death</PopupHighlight> when cells are
          no longer needed. It induces{" "}
          <PopupHighlight>cell cycle arrest</PopupHighlight>, the inability of
          the cell to continue dividing, which can be an alarm response to
          prevent the propagation of dysfunctional cells.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Day Lily",
    subtitle: "Hemerocallis Fulva",
    type: "plantFamily",
    whatIsIt: (
      <PopupBioDesc>
        Daylily, also known as Hemerocallis fulva, is a flower that is
        traditionally used for soothing in Chinese dietary therapy.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "NIH: ",
        underlinedText: "PMC3924984",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC3924984/",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC7697716",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC7697716/#:~:text=Flavonoids%20are%20phytochemical%20compounds%20present,%2Dinflammatory%2C%20and%20antiviral%20properties.",
      },
      {
        text: "NIH: ",
        underlinedText: "PMC3249911",
        link: "https://pmc.ncbi.nlm.nih.gov/articles/PMC3249911/",
      },
      {
        text: "NIH: ",
        underlinedText: "NBK493187",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK493187/",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain, Immune System, & Heart</PopupBioTitle>
        <PopupBioDesc>
          Daylilies are rich in <PopupHighlight>flavonoids</PopupHighlight>,{" "}
          <PopupHighlight>antioxidants</PopupHighlight> that have{" "}
          <PopupHighlight>neuroprotective</PopupHighlight> effects in the brain,{" "}
          <PopupHighlight>anti-inflammatory</PopupHighlight> properties in the
          immune system, and <PopupHighlight>cardio-protective</PopupHighlight>{" "}
          effects in the heart.
        </PopupBioDesc>
        <PopupBioDesc>
          Studies indicate that daylily flowers greatly improve sleeping and
          memory.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Daylily flowers are rich in{" "}
          <PopupHighlight>antioxidants</PopupHighlight>, molecules that delay or
          inhibit cell damage by scavenging{" "}
          <PopupHighlight>free radicals</PopupHighlight>. Free radicals are
          toxic and beneficial compounds that can be either helpful or harmful
          to the body.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Guarana",
    subtitle: "Paullinia Cupana (Caffeine)",
    type: "plantFamily",
    whatIsIt: (
      <PopupBioDesc>
        Guarana (Paullinia cupana) is a plant native to the Amazon basin,
        particularly Brazil, recognized for its seeds’ high caffeine content.
        The seeds contain approximately 2–8% caffeine, surpassing the 1–3% found
        in coffee beans.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "DOI: ",
        underlinedText: "10.3390/nu16121892",
        link: "https://www.mdpi.com/2072-6643/16/12/1892",
      },
      {
        text: "MDPI: ",
        underlinedText: "2072-6643/15/2/434",
        link: "https://www.mdpi.com/2072-6643/15/2/434",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          The caffeine in guarana acts as an adenosine receptor antagonist,
          promoting wakefulness and reducing perceived fatigue. Studies have
          explored guarana’s potential effects on cognitive performance,
          suggesting it may enhance alertness and reaction time. (MDPI) However,
          the exact influence and safety profile of guarana in various dosages
          are still under investigation.
        </PopupBioDesc>
      </>
    ),
  },
  {
    name: "Chamomile",
    subtitle: "Chamomile Extract",
    type: "plantFamily",
    whatIsIt: (
      <PopupBioDesc>
        Chamomile is an herb that belongs to the Asteraceae (daisy or sunflower)
        plant family and is grown around the world. Chamomile has been used as a
        traditional medicine for thousands of years but further research is
        needed on effects.
      </PopupBioDesc>
    ),
    scienceLinks: [
      {
        text: "PMCID: ",
        underlinedText: "PMC9611340",
        link: "https://www.mdpi.com/2072-6643/16/12/1892",
      },
    ],
    biologicalDetails: (
      <>
        <PopupBioTitle>Brain</PopupBioTitle>
        <PopupBioDesc>
          Chamomile tea has long been used for calmness and sleep disorders.
          Some authors reported that the sedative effect is due to a flavonoid,
          apigenin, found in chamomile.
        </PopupBioDesc>
        <PopupBioDesc>
          Chamomile contains apigenin, a bioactive compound that has been
          observed to bind to GABA receptors, which may promote relaxation and a
          calming effect on the nervous system. Preliminary studies suggest it
          could help with mild anxiety and support sleep, though more rigorous
          clinical trials are needed to confirm these effects.
        </PopupBioDesc>

        <PopupBioTitle>Cells</PopupBioTitle>
        <PopupBioDesc>
          Chamomile's flavonoids, such as apigenin and luteolin, exhibit
          antioxidant activity that may help protect cells from oxidative
          stress, a contributor to aging and various diseases. Its potential
          anti-inflammatory properties have been linked to modulation of
          inflammatory pathways, but the mechanisms and long-term effects
          require further exploration.
        </PopupBioDesc>

        <PopupBioTitle>Stomach</PopupBioTitle>
        <PopupBioDesc>
          Chamomile has traditionally been used to alleviate digestive issues.
          Research highlights its possible role in relaxing gastrointestinal
          smooth muscles, which may help with conditions like indigestion and
          mild stomach cramps. Its potential effects against Helicobacter
          pylori, a bacterium linked to ulcers, have been observed in some
          studies, but the evidence is not yet conclusive.
        </PopupBioDesc>
      </>
    ),
  },
];
