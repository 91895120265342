import "./Disclaimer.scss";
import { Grid, Link } from "@mui/material";
import { disclaimerArray } from "./disclaimer.data.types";

const Disclaimer = () => {
  return (
    <div className="Disclaimer">
      <p className="font-quattro md:text-lg text-sm leading-5 mt-6">
        Disclaimer: All information is sourced from medical journals based on specific demographics and dosages, which may not apply universally. Please consult a healthcare professional before using supplements. This content is for educational purposes only.
      </p>
      <Grid
        container
        spacing={0.5}
        className="CustomRow font-quattro text-xs md:my-4 my-5 justify-around sm:justify-center"
      >
        {disclaimerArray.map((item, i) => (
          <Grid item key={i}>
            <div className="mx-3 pl-0">
              <span className="ListNumber">{item.number}</span>
              {item.text}
              <Link
                target="_blank"
                rel="noreferrer"
                href={item.link}
                className="text-white decoration-white"
              >
                {item.underlinedText}
              </Link>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
export default Disclaimer;
